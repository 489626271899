<template>
  <main class="PackagesHistory">
    <TheSubHeader
      icon="package-request"
      title="Histórico de recebimento de pacotes"
      page-title-tag="Packages History"
      :actions="['back']"
      type="filter"
      organizer-gap="1rem"
      organizer-columns="235px repeat(5, 1fr)"
    >
      <template #beforeButtons>
        <BaseButton
          color="primary-base"
          label="Expedir Romaneio"
          :track="trackSubheaderButtonString('Packages History', 'Packages Dispatch')"
          @click="openPackagesDispatchModal"
        />
      </template>
      <template #filter>
        <!-- Date -->
        <BaseDatePicker
          v-model="formattedDateRange"
          label="Intervalo entre datas (max. 31 dias)"
          :max-range="31"
          :not-after="todayParsed"
          @input="resetSearch()"
        />
        <!-- Store -->
        <BaseTreeSelect
          ref="lojista"
          v-model="filters.storeLabel"
          name="Lojista"
          label="Lojista"
          placeholder="Selecione"
          multiple
          :select-options="null"
          :fetch-options="'stores/fetchStoresAsync'"
          @input="resetSearch()"
        />
        <BaseTreeSelect
          ref="query"
          v-model="filters.receiptCodes"
          name="Nº do recebimento"
          label="Nº do recebimento"
          placeholder="Pesquisar"
          no-options-text="Digite e pressione Enter"
          no-results-text="Digite e pressione Enter"
          multiple
          :select-all="false"
          :enable-paste="true"
          :allow-create="true"
          :disable-branch-nodes="true"
          @input="resetSearch()"
        />
        <!-- Status -->
        <BaseTreeSelect
          ref="status"
          v-model="filters.status"
          name="Status"
          label="Status"
          placeholder="Selecione"
          :select-options="statusList"
          @input="resetSearch()"
        />
        <!-- Orders -->
        <BaseTreeSelect
          ref="query"
          v-model="filters.orders"
          name="Nº de pedido"
          label="Nº de pedido"
          placeholder="Pesquisar"
          no-options-text="Digite e pressione Enter"
          no-results-text="Digite e pressione Enter"
          multiple
          :select-all="false"
          :enable-paste="true"
          :allow-create="true"
          :disable-branch-nodes="true"
          :max-items="300"
          @input="resetSearch()"
        />
        <!-- Search -->
        <BaseButton
          label="Pesquisar"
          theme="icon-left"
          color="primary-base"
          filled
          icon="filter"
          text-color="white"
          @click="resetSearch()"
        />
      </template>
      <template v-if="allTags.length" #tag>
        <FiltersTag :tags="allTags" @removeTag="removeTag" @clear="clearAllTags" />
      </template>
    </TheSubHeader>

    <div class="PackagesHistory__content">
      <div class="PackagesHistory__table">
        <DataTable
          :header="packagesHistory.data.headers"
          :table="packagesHistory.data.content"
          is-sticky
          @download="openPackagesReceivedModal"
        />
        <Pagination class="PackagesHistory__pagination" @page="search()" @paginate="search()">
          <div class="PackagesHistory__DispatchLabels">
            <div v-for="s in statusList" :key="s.id" class="PackagesHistory__DispatchLabels--label">
              <span :style="`background-color: ${s.color}`" />
              {{ s.name }}
            </div>
          </div>
        </Pagination>
      </div>
    </div>

    <PackagesReceivedModal
      ref="PackagesReceivedModal"
      @edit="editPackagesReceived"
    />

    <PackagesReceivedEditModal
      ref="PackagesReceivedEditModal"
      @search="searchReceived"
    />

    <PackagesDispatchModal
      ref="PackagesDispatchModal"
      @confirm="search"
    />
  </main>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import { TheSubHeader } from '@/components/organisms'
import { BaseTreeSelect, BaseButton, BaseDatePicker } from '@/components/atoms'
import {
  DataTable,
  FiltersTag,
  Pagination
} from '@/components/molecules'
import PackagesDispatchModal from './PackagesDispatchModal'
import PackagesReceivedModal from './PackagesReceivedModal'
import PackagesReceivedEditModal from './PackagesReceivedEditModal'

export default {
  name: 'PackagesHistory',
  components: {
    TheSubHeader,
    BaseDatePicker,
    BaseButton,
    BaseTreeSelect,
    DataTable,
    FiltersTag,
    Pagination,
    PackagesDispatchModal,
    PackagesReceivedModal,
    PackagesReceivedEditModal
  },
  data() {
    return {
      filters: {
        dateRange: {
          init: null,
          end: null,
        },
        storeLabel: [],
        status: null,
        receiptCodes: [],
        orders: []
      },
      allTags: [],
      updating: false,
      modal: false,
      statusList: [
        {id: '0', name: 'Aguardando expedição', color: '#FFA73F' },
        {id: '1', name: 'Romaneio expedido', color: '#3EBA1F' },
      ]
    }
  },

  computed: {
    ...mapGetters({
      packagesReportsRequestedWithLoading: 'packages/packagesReportsRequestedWithLoading'
    }),

    ...mapState({
      page: state => state.pagination.page,
      paginate: state => state.pagination.paginate,
      packagesHistory: state => state.packages.packagesHistory,
    }),

    todayParsed() {
      let today = new Date()
      return Date.parse(today)
    },

    formattedDateRange: {
      get: function() {
        return [this.filters.dateRange.init, this.filters.dateRange.end]
      },
      set: function(newValue) {
        this.filters.dateRange.init = newValue[0]
        this.filters.dateRange.end = newValue[1]
      },
    }
  },

  async mounted(){
    this.setLoading(true)
    await this.fetchPackagesHistory()
    this.setPagination(this.packagesHistory)
    this.setLoading(false)
  },

  methods: {
    ...mapActions({
      fetchPackagesHistory: 'packages/fetchPackagesHistory',
      setLoading: 'loading/setLoading',
      clearPagination: 'pagination/clearPagination',
      setPagination: 'pagination/setPagination',
    }),

    async resetSearch() {
      this.clearPagination()
      await this.search()
    },

    async search() {
      this.$nextTick(async () => {
        this.updateTags()

        const params = {
          obj: {
            date_start: this.filters.dateRange.init,
            date_end: this.filters.dateRange.end,
            store_id: this.$refs.lojista.checkSelectAll ? [] : this.filters.storeLabel,
            all_dispatched: this.$refs.status.checkSelectAll ? [] :this.filters.status,
            'id[]': this.filters.receiptCodes,
            'order_number[]': this.filters.orders,
          },
          date_start: this.filters.dateRange.init,
          date_end: this.filters.dateRange.end,
          paginate: this.paginate,
          page: this.page
        }

        try {
          await this.fetchPackagesHistory(params)
          this.setPagination(this.packagesHistory)
        } catch (e) {
          throw new Error(e)
        }
      })
    },

    searchReceived() {
      this.search()
      this.$refs.PackagesReceivedModal?.search()
    },

    removeTag(name, id) {
      this.allTags = this.allTags.filter((tag) => tag.id !== id)
      this.filters.storeLabel = this.filters.storeLabel.filter((tag) => tag.name !== name)
      this.filters.receiptCodes = this.filters.receiptCodes.filter((tag) => tag.name !== name)
      this.filters.orders = this.filters.orders.filter((tag) => tag.name !== name)
      this.resetSearch()
    },

    clearAllTags(){
      this.allTags = []
      this.filters.storeLabel = []
      this.filters.receiptCodes = []
      this.filters.orders = []
      this.resetSearch()
    },

    updateTags(){
      this.allTags = [
        ...this.filters.storeLabel,
        ...this.filters.receiptCodes,
        ...this.filters.orders
      ]
    },

    // Received Packages
    async openPackagesReceivedModal(row) {
      this.$refs.PackagesReceivedModal?.open(row)
    },

    // Dispatch Packages
    openPackagesDispatchModal() {
      this.$refs.PackagesDispatchModal?.open()
    },

    // Edit Packages Received
    editPackagesReceived(data) {
      this.$refs.PackagesReceivedEditModal?.open(data)
    }
  }
}

</script>

<style lang="scss">
.PackagesHistory {
  .PackagesHistory__content {
    display: grid;
    grid-template-columns: 1fr minmax(min-content, 1180px) 1fr;
    grid-template-areas: ". main .";
    grid-column-gap: 1rem;
    padding-bottom: 3rem;
  }

  .PackagesHistory__table {
    grid-area: main;
  }

  .PackagesHistory__pagination {
    grid-area: main;
  }

  &__DispatchLabels {
    display: flex;
    gap: 20px;
    &--label {
      display: flex;
      gap: 10px;
      justify-content: center;
      align-items: center;
      font-size: 14px;
      span {
        width: 15px;
        height: 15px;
        border-radius: 100%;
        display: block;
      }
    }
  }
}
</style>