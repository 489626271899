<template>
  <Modal v-if="modal" class="PackagesReceived__Modal" @close="close">
    <template #header>
      <div class="PackagesReceived__Modal__header">
        Pacotes confirmados
      </div>
    </template>

    <template #body>
      <p class="PackagesReceived__Modal__disclaimer">
        Veja abaixo o relatório dos pacotes que foram confirmados o recebimento:
      </p>
      <DataTable
        class="PackagesReceived__Modal__table"
        :header="packagesReceived.data.headers"
        :table="packagesReceived.data.content"
        @download="download"
        @edit="edit"
      />
    </template>
  </Modal>
</template>
<script>
import { mapActions, mapState } from 'vuex'
import { Modal, DataTable } from '@/components/molecules'
import { packages } from '@/api'

export default {
  name: 'PackagesReceived',
  components: {
    Modal,
    DataTable,
  },
  data() {
    return {
      modal: false,
      order_products_reception_id: null
    }
  },
  computed: {
    ...mapState({
      packagesReceived: state => state.packages.packagesReceived
    })
  },

  mounted () {
  },
  methods: {
    ...mapActions({
      setLoading: 'loading/setLoading',
      fetchPackagesReceived: 'packages/fetchPackagesReceived'
    }),

    async open(data) {
      this.order_products_reception_id = data.order_products_reception_id
      await this.search()
      this.modal = true
      this.$emit('open')
    },

    async search() {
      await this.fetchPackagesReceived({
        obj: {
          order_products_reception_id: this.order_products_reception_id,
        },
        paginate: 999,
        page: 1
      })
    },

    close() {
      this.modal = false
      this.$emit('close')
    },

    async download(data) {
      this.setLoading(true)
      await packages.downloadPackagesList(data.id, (r) => r, (e) => e, () => this.setLoading(false))
    },

    edit(data) {
      this.$emit('edit', data)
    }
  }
}
</script>
<style lang="scss" scoped>
.PackagesReceived__Modal {
  /deep/ .Modal__container {
    width: 500px;
    height: auto;
    max-height: calc(100vh - 80px);
    @media screen and (max-width: 768px) {
      width: calc(100vw - 40px);
    }
  }

  /deep/ .Modal__containerBody {
    height: auto;
  }

  &__header {
    padding: 5px;
  }

  &__disclaimer {
    padding: 15px 45px;
    text-align: center;
  }

  &__table {
    margin: 15px 20px;
    width: calc(100% - 40px)
  }

}
</style>