<template>
  <Fragment>
    <Modal v-if="modal" class="PackagesReceivedEdit__Modal" @close="close">
      <template #header>
        <div class="PackagesReceivedEdit__Modal__header">
          <strong>Edição do romaneio:</strong> {{ fileId }} | <strong>Transportadora:</strong> {{ transporter }}
        </div>
      </template>

      <template #body>
        <DataTable
          v-model="form.orders"
          class="PackagesReceivedEdit__Modal__table"
          :header="packagesReceivedDetail.data.headers"
          :table="packagesReceivedDetail.data.content"
        />
        <div class="PackagesReceivedEdit__Modal__Action">
          <BaseButton
            label="Remover"
            theme="icon-left"
            icon="trash"
            color="red"
            filled
            text-color="white"
            :disabled="!form.orders.length"
            @click="confirm = true"
          />
        </div>
      </template>
    </Modal>
    <Dialog
      v-if="confirm"
      :warning="confirmAskMessage"
      label-confirm="Sim"
      label-cancel="Não"
      @confirm="confirmRemove"
      @cancel="confirm = false"
    />
  </Fragment>
</template>
<script>
import { Fragment } from 'vue-fragment'
import { mapActions, mapState } from 'vuex'
import { BaseButton } from '@/components/atoms'
import { Modal, DataTable, Dialog } from '@/components/molecules'

export default {
  name: 'PackagesReceivedEdit',
  components: {
    Fragment,
    BaseButton,
    Modal,
    DataTable,
    Dialog
  },
  data() {
    return {
      confirm: false,
      modal: false,
      id: null,
      fileId: null,
      transporter: null,
      form: {
        orders: []
      },
    }
  },
  computed: {
    ...mapState({
      packagesReceivedDetail: state => state.packages.packagesReceivedDetail
    }),

    confirmAskMessage() {
      const codes = this.form.orders.map(({code}) => code)
      const s = codes.length > 1 ? 's' : ''

      return `Tem certeza que deseja remover o${s} pacote${s} com o${s} código${s}: ${s ? '<br/>' : ''} ${codes.join('<br/>')} ?`
    }
  },

  methods: {
    ...mapActions({
      setLoading: 'loading/setLoading',
      fetchPackagesReceivedDetail: 'packages/fetchPackagesReceivedDetail',
      removePackages: 'packages/removePackages'
    }),

    async open(data) {
      this.packingListId = data.id
      this.fileId = data.file_id
      this.transporter = data.transporter
      await this.fetchPackagesReceivedDetail(data.id)
      this.modal = true
    },

    close() {
      this.modal = false
    },

    async confirmRemove() {
      this.confirm = false
      const packageIds = this.form.orders.map(({ id }) => id)
      await this.removePackages({ packingListId: this.packingListId, packages: packageIds })
      await this.fetchPackagesReceivedDetail(this.packingListId)
      this.$emit('search')
    }
  }
}
</script>
<style lang="scss" scoped>
.PackagesReceivedEdit {
  &__Modal {
    /deep/ .Modal__container {
      width: 650px;
      height: auto;
      max-height: calc(100vh - 80px);
      @media screen and (max-width: 768px) {
        width: calc(100vw - 40px);
      }
    }

    /deep/ .Modal__containerBody {
      height: auto;
    }

    &__header {
      padding: 5px;
    }

    &__table {
      margin: 15px 20px;
      width: calc(100% - 40px)
    }

    &__Action {
      display: flex;
      justify-content: flex-end;
      padding: 0 20px 10px 0;

      button {
        width: 150px;
      }
    }
  }
}
</style>